import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  notification,
} from "antd";

import {
  getMe,
  listUsers,
  deleteUser,
  getUserCsv,
  searchUsers,
  listTags,
  getProfessionList,
  updateUserRoles,
  addUserLemonCertification,
  removeUserLemonCertification,
  createAppAnnouncement,
  disableUser,
  enableUser,
} from "../../services";
import {
  IJournal,
  IOrganisation,
  IProfession,
  IQuery,
  ITag,
  IUser,
  SupportedLanguage,
} from "../../model";
import { userMapping } from "../../config/userMapping";
import { countryList } from "../../config/countries";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext, IGlobalContext } from "../../context/global.context";
import { Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import slugify from "slugify";
import { ColumnsType } from "antd/lib/table";
import { languageLabel, languageOptions } from "../../utils/helpers";
import { Flex } from "../../components/shared/global";
import UserRoomsAssociator from "~/pages/users/UserRoomsAssociator";
import UserOrganisationAssociator from "./UserOrganisationAssociator";

const USER_ENUM = {
  juisci: "Administrateur Juisci",
  admin_org: "Administrateur organisation",
  user: "Utilisateur",
};

export const UserList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { organisationList } = useContext(GlobalContext) as IGlobalContext;
  const search = new URLSearchParams(location.search);
  const [innerUserList, setInnerUserList] = useState<IUser[] | null>(null);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [blobUrl, setBlobUrl] = useState<string | null>("");
  const [isBlobLoading, setBlobLoading] = useState<boolean>(false);
  const [queryMeta, setQueryMeta] = useState<{
    total: number;
    offset: number;
    limit: number;
  }>({
    total: 0,
    offset: 0,
    limit: 100,
  });
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    search?.get("language") as SupportedLanguage
  );
  const [innerTagList, setInnerTagList] = useState<ITag[]>([]);
  const [innerProfessionList, setInnerProfessionList] = useState<IProfession[]>(
    []
  );
  const [innerOrganisation, setInnerOrganisation] = useState<
    string | undefined
  >(undefined);

  const [searchValue, setSearchValue] = useState<string>("");
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [device, setDevice] = useState<string | undefined>(undefined);
  const [mainSpecialty, setMainSpecialty] = useState<string | undefined>(
    undefined
  );
  const [origin, setOrigin] = useState<string | undefined>(undefined);
  const [profession, setProfession] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);

  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
  const [isRolesConfirmOpen, setIsRolesConfirmOpen] = useState(false);
  const [showAdModal, setShowAdModal] = useState(false);
  const [form] = Form.useForm();

  const fetchUsers = async (limit?: number, offset?: number) => {
    const query: IQuery = {
      limit: limit || 100,
      offset: offset || 0,
      country,
      device,
      language,
      mainSpecialty,
      origin,
      profession,
      organisation: innerOrganisation,
    };

    setInnerUserList(null);
    if (searchValue) {
      const users = await searchUsers(searchValue, query);

      setInnerUserList(users);
      setPage(1);
    } else {
      const { docs: userList, meta } = await listUsers(query);

      setInnerUserList(userList);
      setQueryMeta(meta);
    }

    if (!innerTagList.length) {
      const tagList = await listTags();
      setInnerTagList(tagList);
    }

    if (!innerProfessionList.length) {
      const { docs: professionList } = await getProfessionList({ limit: 100 });
      setInnerProfessionList(professionList);
    }
  };

  const fetch = async () => {
    if (!currentUser) {
      try {
        const loggedUser = await getMe();
        setCurrentUser(loggedUser);
      } catch {
        navigate("/");
      }
    }
  };

  const showRolesModal = (user: IUser) => {
    setIsRolesModalOpen(true);
    setSelectedRoles(user.roles);
    setSelectedUser(user);
  };

  const handleRolesCancel = () => {
    setIsRolesModalOpen(false);
    setSelectedRoles([]);
  };

  const handleRolesConfirmOk = async () => {
    setIsRolesConfirmOpen(false);
    setIsRolesModalOpen(false);
    if (selectedUser && selectedRoles.length > 0) {
      const updatedUser = await updateUserRoles(
        selectedUser._id,
        selectedRoles
      );
      if (updatedUser) {
        await fetchUsers(
          pageSize,
          (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
        );
      }
      setSelectedRoles([]);
    }
  };

  const handleRolesOk = async () => {
    setIsRolesConfirmOpen(true);
  };

  const handleRolesConfirmCancel = () => {
    setIsRolesConfirmOpen(false);
  };

  const handleLanguageChange = (language: SupportedLanguage) => {
    navigate(`/sensitive-data/users?language=${language}`);
    setLanguage(language);
    handlePaginationChange(1);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    handlePaginationChange(1);
  };

  const handleUserDeletion = async (uid: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: "This action is irreversible",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await deleteUser(uid);
        fetchUsers();
      },
    });
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      await fetchUsers(
        pageSize,
        (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize
      );
      setPageSize(pageSize);
    }
    setPage(page);
  };

  const handleChange = (
    value: string,
    setFunction: (value: string) => void
  ) => {
    setFunction(value);
    setPage(1);
  };

  const handleRoleChange = (value: string[]) => {
    setSelectedRoles(value);
  };

  const handleDownloadCSV = async () => {
    const query = {
      limit: pageSize || 100,
      offset: (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize || 0,
      country,
      device,
      language,
      mainSpecialty,
      origin,
      profession,
      organisation: innerOrganisation,
    };

    if (blobUrl) return;

    setBlobLoading(true);

    const userCsv = await getUserCsv(query);
    if (userCsv) {
      const blobConfig = new Blob([userCsv], {
        type: "text/csv;charset=utf-8",
      });
      const url = URL.createObjectURL(blobConfig);
      setBlobUrl(url);

      const links = document.createElement("a");
      document.body.appendChild(links);
      links.download = "users.csv";
      links.href = url;
      links.click();
      document.body.removeChild(links);
      setBlobUrl(null);
    }

    setBlobLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    language,
    searchValue,
    country,
    device,
    mainSpecialty,
    origin,
    profession,
    innerOrganisation,
  ]);

  const handleLemonCertify = async (user: IUser) => {
    if (user.lemonCertified) {
      await removeUserLemonCertification(user._id);
      fetchUsers();
      notification.success({
        message: "❌ User uncertified successfully",
      });
    } else {
      await addUserLemonCertification(user._id);
      fetchUsers();
      notification.success({
        message: "🍋 User certified successfully",
      });
    }
  };

  const handleCreateAnnouncement = async () => {
    const contents: {
      title: string;
      content: string;
      cta: string;
      language: SupportedLanguage;
    }[] = [];
    const inputData = form.getFieldsValue();

    Object.values(SupportedLanguage).forEach((language) => {
      const title = inputData[`title-${language}`];
      const content = inputData[`content-${language}`];
      const cta = inputData[`cta-${language}`];

      if (title && content) {
        contents.push({
          title,
          content,
          cta,
          language,
        });
      }
    });

    await createAppAnnouncement({
      contents,
      organisation: inputData["organisation"],
    });

    setShowAdModal(false);
    form.resetFields();

    notification.success({
      message: "✅ Announcement created successfully",
    });
  };

  const handleDisableUser = async (user: IUser) => {
    if (!innerUserList) return;

    if (user.enabled) {
      await disableUser(user._id);
      notification.success({
        message: "User disabled successfully",
      });
      setInnerUserList(
        innerUserList.map((u) => {
          if (u._id === user._id) {
            return { ...u, enabled: false };
          }
          return u;
        })
      );
    } else {
      await enableUser(user._id);
      notification.success({
        message: "User enabled successfully",
      });
      setInnerUserList(
        innerUserList.map((u) => {
          if (u._id === user._id) {
            return { ...u, enabled: true };
          }
          return u;
        })
      );
    }
  };

  const columns: ColumnsType<IUser> = [
    ...Object.keys(userMapping)
      .filter((key) => key !== "preferredFormats")
      .map((key: string) => {
        if (["main_specialty", "profession"].includes(key))
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 150,
            render: (value: ITag | IProfession) => {
              return value ? value?.translations?.en : "";
            },
          };
        if (key === "country")
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 100,
            render: (value: string) => {
              return value ? countryList[value] : "";
            },
          };

        if (key === "journal") {
          return {
            title: "Journals",
            dataIndex: "journals",
            key: "journals",
            width: 240,
            render: (journal: IJournal[]) => {
              return journal?.map((j) => j?.name).join(", ");
            },
          };
        }
        if (key === "birthdate")
          return {
            title: userMapping[key],
            dataIndex: key,
            key: key,
            width: 100,
            render: (birthdate: Date) => {
              return birthdate ? new Date(birthdate).toLocaleDateString() : "";
            },
          };
        return {
          title: userMapping[key],
          dataIndex: key,
          key,
          width: 100,
        };
      }),
    {
      title: "Signup date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 100,
    },
    {
      title: "Last login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: 100,
    },
    {
      title: "Organisations",
      width: 240,
      render: (_text: string, record: IUser) => (
        <UserOrganisationAssociator user={record} />
      ),
    },
    {
      title: "Rooms",
      dataIndex: "rooms",
      width: 400,
      render: (_, user: IUser) => <UserRoomsAssociator user={user} />,
    },
    {
      title: "RPPS",
      dataIndex: "rpps",
      key: "rpps",
      width: 100,
      render: (rpps: string) => rpps || "N/A",
    },
    {
      title: "Roles",
      width: 240,
      render: (_text: string, record: IUser) => (
        <div
          style={{
            cursor: "pointer",
            color: "#1890ff",
          }}
          onClick={() => showRolesModal(record)}
        >
          {record.roles
            .map((role: string) => USER_ENUM[role as keyof typeof USER_ENUM])
            .join(", ")}
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      fixed: "right",
      render: (_text: string, user: IUser) => (
        <Flex justify='center' align='center' gap={8}>
          <Select
            style={{ width: 150 }}
            placeholder='Select action'
            onChange={(value: "certify" | "delete") =>
              value === "certify"
                ? handleLemonCertify(user)
                : handleUserDeletion(user.uid)
            }
          >
            <Select.Option value={"certify"}>
              <Flex
                align={"center"}
                gap={8}
                style={{
                  color: user.lemonCertified ? "#FF4D4F" : "#FFC81A",
                  fontWeight: 600,
                }}
              >
                <CheckCircleOutlined />
                {user.lemonCertified ? "Uncertify" : "Certify"}
              </Flex>
            </Select.Option>
            <Select.Option value={"delete"}>
              <Flex align='center' gap={8}>
                <DeleteOutlined style={{ color: "red" }} />
                <div style={{ color: "red" }}>Delete</div>
              </Flex>
            </Select.Option>
          </Select>
          <Switch
            defaultChecked={user.enabled}
            onChange={() => handleDisableUser(user)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Modal
          open={showAdModal}
          title='Make an announcement'
          onOk={handleCreateAnnouncement}
          onCancel={() => setShowAdModal(false)}
        >
          <Form layout='vertical' form={form}>
            <Form.Item label='Organisation' name='organisation'>
              <Select
                placeholder='Organisation'
                options={organisationList.map((org) => ({
                  label: org.name,
                  value: org._id,
                }))}
                allowClear
              />
            </Form.Item>

            <Tabs
              items={Object.values(SupportedLanguage).map((lng) => ({
                key: lng,
                label: languageLabel[lng],
                children: (
                  <div>
                    <Form.Item label='Title' name={"title-" + lng}>
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item label='Content' name={"content-" + lng}>
                      <Input.TextArea
                        placeholder='Your message here...'
                        autoSize={{ minRows: 3, maxRows: 9 }}
                      />
                    </Form.Item>
                    <Form.Item label='CTA' name={"cta-" + lng}>
                      <Input placeholder='CTA' />
                    </Form.Item>
                  </div>
                ),
              }))}
            />
          </Form>
        </Modal>
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Users</h1>
          <div>
            <Button
              onClick={() => setShowAdModal(true)}
              icon={<SoundOutlined />}
            >
              Announcement
            </Button>
            <Divider type='vertical' />
            <Link to='/sensitive-data/users/lemon-check-requests'>
              <Button icon={<CheckCircleOutlined />}>
                LemonCertified Requests
              </Button>
            </Link>
            <Divider type='vertical' />
            <Select
              placeholder='Language'
              defaultValue={language}
              style={{ marginRight: 10, width: 120 }}
              onSelect={handleLanguageChange}
              loading={!innerUserList}
              allowClear
              options={languageOptions}
            />
            <Button
              type='primary'
              download='users.csv'
              loading={isBlobLoading}
              onClick={handleDownloadCSV}
            >
              Download as CSV
            </Button>
          </div>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Input.Search
              loading={!innerUserList}
              placeholder='Search user'
              allowClear
              onSearch={handleSearchChange}
            />
          </Col>
          <Col span={8}>
            <Select
              loading={!innerTagList.length}
              showSearch
              allowClear
              placeholder='Select Main specialty'
              onChange={(value: string) =>
                handleChange(value, setMainSpecialty)
              }
              filterOption={(input: string, option) => {
                if (!option?.value) return false;
                return (option.key as string).includes(
                  slugify(input).toLocaleLowerCase()
                );
              }}
              style={{ width: "100%" }}
            >
              {innerTagList
                ?.filter((tag: ITag) => !Object.keys(tag).includes("parent"))
                ?.sort((a, b) =>
                  a.translations["en"].localeCompare(b.translations["en"])
                )
                ?.map((option: ITag) => (
                  <Select.Option key={option.uid} value={option._id}>
                    {`${option.translations["en"]} ${
                      option?.parent?.uid
                        ? `(${option.parent.translations["en"]})`
                        : ""
                    }`}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              showSearch
              placeholder='Country'
              allowClear
              style={{ width: "100%" }}
              onChange={(value: string) => handleChange(value, setCountry)}
              filterOption={(input: string, option) => {
                if (!option?.children) return false;

                const children = slugify(
                  option.children as unknown as string
                ).toLocaleLowerCase();
                const query = slugify(input).toLocaleLowerCase();

                return children.includes(query);
              }}
            >
              {Object.keys(countryList).map((countryCode: string) => (
                <Select.Option key={countryCode} value={countryCode}>
                  {countryList[countryCode]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "10px",
          }}
          gutter={20}
        >
          <Col span={8}>
            <Select
              loading={!innerUserList}
              allowClear
              placeholder='Select Device'
              onChange={(value: string) => handleChange(value, setDevice)}
              style={{ width: "100%" }}
            >
              <Select.Option key={"iod"} value={"ios"}>
                iOS
              </Select.Option>
              <Select.Option key={"android"} value={"android"}>
                Android
              </Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              allowClear
              placeholder='Select Origin'
              onChange={(value: string) => handleChange(value, setOrigin)}
              style={{ width: "100%" }}
            >
              <Select.Option key={"app"} value={"app"}>
                App
              </Select.Option>
              <Select.Option key={"website"} value={"website"}>
                Website
              </Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select
              loading={!innerProfessionList.length}
              showSearch
              allowClear
              placeholder='Select Profession'
              onChange={(value: string) => handleChange(value, setProfession)}
              style={{ width: "100%" }}
              filterOption={(input: string, option) => {
                if (!option?.value) return false;
                return (option.key as string).includes(
                  slugify(input).toLocaleLowerCase()
                );
              }}
            >
              {innerProfessionList?.map((option: IProfession) => (
                <Select.Option key={option.uid} value={option._id}>
                  {option.translations["en"]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "10px",
          }}
          gutter={20}
        >
          <Col span={8}>
            <Select
              loading={!organisationList.length}
              showSearch
              placeholder='Organisation'
              allowClear
              style={{ width: "100%" }}
              onChange={(value: string) =>
                handleChange(value, setInnerOrganisation)
              }
              filterOption={(input: string, option) => {
                if (!option?.value) return false;
                return (option.key as string).includes(
                  slugify(input).toLocaleLowerCase()
                );
              }}
            >
              {organisationList.map((organisation: IOrganisation) => (
                <Select.Option key={organisation.uid} value={organisation._id}>
                  {organisation.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div style={{ height: 16 }} />
        <Spin spinning={!innerUserList} tip='Loading...'>
          <Modal
            title='Confirmation'
            open={isRolesConfirmOpen}
            onOk={handleRolesConfirmOk}
            onCancel={handleRolesConfirmCancel}
          >
            <p>Are you sure you want to update this user's roles?</p>
          </Modal>
          <Modal
            title='Select roles'
            open={isRolesModalOpen}
            onOk={handleRolesOk}
            onCancel={handleRolesCancel}
          >
            <p>{`Select roles for user ${selectedUser?.firstname} ${selectedUser?.lastname}`}</p>
            <Select
              value={selectedRoles}
              onChange={handleRoleChange}
              showSearch
              size='middle'
              mode='multiple'
              placeholder='Please select'
              style={{ width: "100%" }}
            >
              {(selectedUser?.organisations?.[0]?.uid === "juisci" ||
              !selectedUser?.organisations?.length
                ? ["juisci", "user"]
                : ["juisci", "admin_org", "user"]
              )?.map((role: string) => (
                <Select.Option key={role} value={role}>
                  {USER_ENUM[role as keyof typeof USER_ENUM]}
                </Select.Option>
              ))}
            </Select>
          </Modal>

          <Table
            size='small'
            columns={columns}
            dataSource={
              innerUserList
                ? innerUserList.map((user: IUser, index: number) => ({
                    key: user.uid + "--key" + index,
                    ...user,
                    creationDate: new Date(
                      user?.meta?.creationDate
                    ).toLocaleDateString(),
                    lastLogin: new Date(
                      user?.meta?.lastLogin
                    ).toLocaleDateString(),
                  }))
                : []
            }
            pagination={false}
            scroll={{
              x: "max-content",
              y: "calc(100vh - 360px)",
            }}
            sticky
          />
        </Spin>
        <Pagination
          style={{ marginTop: "10px" }}
          onChange={handlePaginationChange}
          total={queryMeta.total}
          current={page}
          defaultCurrent={1}
          defaultPageSize={100}
          pageSizeOptions={["10", "20", "50", "100"]}
        />
      </Layout.Content>
    </Layout>
  );
};
