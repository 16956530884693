import { useContext, useState } from "react";
import { Button, Modal, notification, Select, Tag } from "antd";
import { GlobalContext } from "~/context/global.context";
import { IUser } from "~/model";
import { Flex } from "~/components/shared/global";
import { EditOutlined } from "@ant-design/icons";
import { updateUser } from "~/services";

const UserOrganisationAssociator = ({ user }: { user: IUser }) => {
  const { organisationList } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);

  const handleUpdateUser = async () => {
    try {
      await updateUser(user._id, {
        organisations: [user.organisations[0]?._id],
      });
      notification.success({
        message: "User updated",
        description: `User ${user.email} has been updated`,
      });
    } catch (error) {
      notification.error({
        message: "An error has occured",
        description: (error as Error)?.message || "Please try again later",
      });

      throw error;
    }
  };

  return (
    <div>
      <Modal
        open={visible}
        title='Edit user organisation'
        style={{ width: 300 }}
        onCancel={() => setVisible(false)}
        onOk={handleUpdateUser}
      >
        <Select
          placeholder='Select organisation'
          style={{ width: "100%" }}
          defaultValue={user.organisations[0]?._id}
          options={organisationList.map((org) => ({
            label: org.name,
            value: org._id,
          }))}
        />
      </Modal>
      <Flex align='center' justify='start' gap={4}>
        <Tag color='orange'>{user.organisations[0]?.name || "N/A"}</Tag>
        <Button
          icon={<EditOutlined />}
          type='link'
          onClick={() => setVisible(true)}
        >
          {"Edit"}
        </Button>
      </Flex>
    </div>
  );
};

export default UserOrganisationAssociator;
